import React, { useState, useEffect } from 'react'
import { Box, useToast } from '@chakra-ui/react'
import TableRunning from './TableRunning'
import { FetchCombis, FetchRunning, GetLongDate, parseDate } from '../Queries/Queries';
import { genPDF_combi } from './PdfMakeTable';

var ws = null;
var gm = ""

const RunningForm = ({ selGame, token, wstoken }) => {
    const [running, setrunning] = useState(null)
    const toast = useToast()

    useEffect(() => {
        ws = new WebSocket("wss://websocks.msd4p.com/ws");
        try {
            gm = localStorage.getItem('game');
        } catch (err) {
        }
        GetRunning()
    }, [selGame.gcode])

    useEffect(() => {
        return () => {
            ws.close()
        }
    }, [])

    if (ws !== null) {
        ws.onopen = () => {
            try {
                ws.send(JSON.stringify({
                    "token": wstoken
                }));
            } catch (err) {

            }
        };

        ws.onmessage = function (event) {
            const json = JSON.parse(event.data);
            if (json.channel && json.channel === ("central-" + gm)) {
                setrunning(json.pub.data)
            };
        }
    }

    const onClickDownload = () => {
        selGame.gcode && FetchCombis(token, gm).then(res => {
            if (res.err) {
                toast({
                    title: "FETCH REPORT ERROR!",
                    description: res.data.message,
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                    position: 'top',
                    variant: 'left-accent'
                })
            } else {
                setrunning(res.data)
                genPDF_combi(res.data, "CENTRAL " + gm.toUpperCase() + " COMBINATION -AMOUNT REPORT", parseDate(new Date), GetLongDate(), gm)
            }
        }).catch()

    }

    const GetRunning = () => {
        setrunning(null)
        selGame.gcode && FetchRunning(token, selGame.gcode).then(res => {
            if (res.err) {
                toast({
                    title: "FETCH REPORT ERROR!",
                    description: res.data.message,
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                    position: 'top',
                    variant: 'left-accent'
                })
            } else {
                setrunning(res.data)
            }
        }).catch()
    }

    return (
        <Box>
            <TableRunning
                selGame={selGame}
                combis={running}
                onClickDownload={onClickDownload}
            />
        </Box>
    )
}

export default RunningForm